import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useSnackbar } from '../context/snackbarContext'
import { useShippingInfo, useUpdateShippingAddress } from '../hooks/shippingInfoHooks'
import { CommonDialog } from './dialog'
import { Divider, Stack, TextField, Typography, styled } from '@mui/material'
import { SectionTitle } from './typography'
import { InputTitle } from './form'
import { RectButton } from './button'
import AddBoxIcon from '@mui/icons-material/AddBox'

import { useForm } from 'react-hook-form'

export const EditShippingInfoDialog = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  const { data: shippingInfo, isPending } = useShippingInfo(getAccessTokenSilently, getAccessTokenWithPopup)
  const { mutateAsync: updateShipping } = useUpdateShippingAddress()

  const snackbar = useSnackbar()

  const [showAddAddress, setShowAddAddress] = useState(false)

  const {
    register,
    getValues,
    reset,
    trigger,
    formState: { errors }
  } = useForm({
    mode: 'all',
    shouldUnregister: true,
    defaultValues: useMemo(() => {
      return shippingInfo?.shippingInfo?.shippingAddress
    }, [shippingInfo])
  })

  useEffect(() => {
    reset(undefined, { keepDirtyValues: true })
  }, [])

  const submitHandler = useCallback(async () => {
    await trigger(`-1.fullName`)
    await trigger(`-1.fullNameKana`)
    await trigger(`-1.phone`)
    await trigger(`-1.postalCode`)
    await trigger(`-1.address`)

    if (Object.values(errors).length > 0) {
      snackbar.showSnackbar('不正な入力があります', 'warning')
      return
    }

    const values = getValues()

    await updateShipping({ shippingAddress: Object.values(values) })

    snackbar.showSnackbar('住所情報を更新しました', 'success')
    window.location.reload()

    onClose()
  }, [onClose, snackbar])

  return (
    <CommonDialog
      onClose={onClose}
      open={open}
      title="Edit Shipping Address"
      subtitle="お届け先の編集"
      onSubmit={submitHandler}
      loading={isPending}
    >
      <SectionTitle>配送先の編集</SectionTitle>

      <Stack px="30px" gap={1} key={'shippingform'}>
        <InputTitle width={200}>登録済みのお届け先</InputTitle>

        {shippingInfo?.shippingInfo ? (
          shippingInfo?.shippingInfo?.shippingAddress.map((addressItem, index) => (
            <>
              <Stack key={index} direction={'row'} alignItems={'center'} gap="50px" sx={{ marginLeft: '80px' }}>
                <Stack height={40} justifyContent={'center'}>
                  <Typography width={130} fontSize={16} fontWeight={500} sx={{ textAlign: 'right' }} color="black">
                    お届け先 {index + 1}
                  </Typography>
                </Stack>
                <Stack height={40} justifyContent={'center'} sx={{ width: '100%' }}>
                  <Divider sx={{ bgcolor: 'black' }} />
                </Stack>
              </Stack>

              {/* お届け先名称 */}
              <Stack
                direction={'row'}
                alignItems={'center'}
                gap="50px"
                sx={{ marginLeft: '80px', marginBottom: '15px' }}
              >
                <Stack height={40} justifyContent={'center'}>
                  <InputTitle sx={{ textAlign: 'right', marginBottom: '15px' }}>お届け先名称</InputTitle>
                </Stack>
                <Stack height={40} justifyContent={'center'}>
                  <StyledTextField
                    size="small"
                    sx={{ width: 190 }}
                    id={'name_' + index}
                    defaultValue={addressItem.fullName}
                    {...register(`${index}.fullName`, {
                      required: '氏名は未入力です',
                      maxLength: { value: 30, message: '氏名は30桁以上は入力できません' },
                      pattern: {
                        value: /^[a-zA-Z\s0-9ぁ-んァ-ヶー一-龥々]+$/,
                        message: '記号文字は入力できません'
                      }
                    })}
                    inputProps={{ maxLength: 30 }}
                    error={Boolean(errors[index]?.fullName?.message)}
                    helperText={errors[index]?.fullName?.message?.toString()}
                  />
                </Stack>
              </Stack>

              {/* フリガナ */}
              <Stack
                direction={'row'}
                alignItems={'center'}
                gap="50px"
                sx={{ marginLeft: '80px', marginBottom: '15px' }}
              >
                <Stack height={40} justifyContent={'center'}>
                  <InputTitle sx={{ textAlign: 'right', marginBottom: '15px' }}>フリガナ</InputTitle>
                </Stack>
                <Stack height={40} justifyContent={'center'}>
                  <StyledTextField
                    size="small"
                    sx={{ width: 190 }}
                    id={'fullNameKana'}
                    defaultValue={addressItem.fullNameKana}
                    {...register(`${index}.fullNameKana`, {
                      required: 'フリガナは未入力です',
                      maxLength: { value: 30, message: 'フリガナは30桁以上は入力できません' },
                      pattern: {
                        value: /^[a-zA-Z\s0-9ぁ-んァ-ヶー一-龥々]+$/,
                        message: '記号文字は入力できません'
                      }
                    })}
                    inputProps={{ maxLength: 30 }}
                    error={Boolean(errors[index]?.fullNameKana?.message)}
                    helperText={errors[index]?.fullNameKana?.message?.toString()}
                  />
                </Stack>
              </Stack>

              {/* 電話番号 */}
              <Stack
                direction={'row'}
                alignItems={'center'}
                gap="50px"
                sx={{ marginLeft: '80px', marginBottom: '15px' }}
              >
                <Stack height={40} justifyContent={'center'}>
                  <InputTitle sx={{ textAlign: 'right', marginBottom: '15px' }}>電話番号</InputTitle>
                </Stack>
                <Stack height={40} justifyContent={'center'}>
                  <StyledTextField
                    size="small"
                    sx={{
                      width: 190,
                      '&  .MuiFormHelperText-root.Mui-error': {
                        marginRight: '-150px'
                      }
                    }}
                    id={'phone_' + index}
                    defaultValue={addressItem.phone}
                    {...register(`${index}.phone`, {
                      required: '電話番号は未入力です',
                      maxLength: { value: 11, message: '電話番号は11桁以上は入力できません' },
                      pattern: {
                        value:
                          /^(0[5-9]0[-(]?[0-9]{4}[-)]?[0-9]{4}|0120[-]?\d{1,3}[-]?\d{4}|050[-]?\d{4}[-]?\d{4}|0[1-9][-]?\d{1,4}[-]?\d{1,4}[-]?\d{4})*$/,
                        message: '有効な電話番号を入力してください'
                      }
                    })}
                    inputProps={{ maxLength: 11 }}
                    error={Boolean(errors[index]?.phone?.message)}
                    helperText={errors[index]?.phone?.message?.toString()}
                  />
                </Stack>
              </Stack>

              {/* 郵便番号 */}
              <Stack
                direction={'row'}
                alignItems={'center'}
                gap="50px"
                sx={{ marginLeft: '80px', marginBottom: '5px' }}
              >
                <Stack height={40} justifyContent={'center'}>
                  <InputTitle sx={{ textAlign: 'right', marginBottom: '15px' }}>郵便番号</InputTitle>
                </Stack>
                <Stack height={40} justifyContent={'center'}>
                  <StyledTextField
                    size="small"
                    sx={{
                      width: 190,
                      '&  .MuiFormHelperText-root.Mui-error': {
                        marginRight: '-150px'
                      }
                    }}
                    id={'postalCode_' + index}
                    defaultValue={addressItem.postalCode}
                    {...register(`${index}.postalCode`, {
                      required: '郵便番号は未入力です',
                      maxLength: { value: 7, message: '郵便番号は7桁以上は入力できません' },
                      pattern: {
                        value: /^[0-9]{7}$/,
                        message: 'ハイフン無しの有効な郵便番号を入力してください'
                      }
                    })}
                    inputProps={{ maxLength: 7 }}
                    error={Boolean(errors[index]?.postalCode?.message)}
                    helperText={errors[index]?.postalCode?.message?.toString()}
                  />
                </Stack>
              </Stack>

              {/* 住所 */}
              <Stack direction={'row'} alignItems={'center'} gap="50px" sx={{ marginLeft: '80px' }}>
                <InputTitle sx={{ textAlign: 'right', marginBottom: '15px' }}>住所</InputTitle>
                <Stack>
                  <StyledTextField
                    size="small"
                    sx={{ width: '100%' }}
                    id={'address' + index}
                    defaultValue={addressItem.address}
                    {...register(`${index}.address`, {
                      required: '住所は未入力です',
                      maxLength: { value: 50, message: '住所は50桁以上は入力できません' }
                    })}
                    inputProps={{ maxLength: 100 }}
                    error={Boolean(errors[index]?.address?.message)}
                    helperText={
                      errors[index]?.address
                        ? errors[index]?.address?.message?.toString()
                        : '※マンション名や部屋番号等がある場合はすべて入力してください'
                    }
                  />
                </Stack>
              </Stack>
            </>
          ))
        ) : (
          <Typography fontSize={16} fontWeight={700}>
            未登録
          </Typography>
        )}

        <RectButton
          variant="contained"
          sx={{
            width: 200,
            background: '#236CB5',
            float: 'right',
            marginLeft: 'auto',
            marginRight: 0,
            marginBottom: '15px'
          }}
          startIcon={<AddBoxIcon />}
          onClick={() => {
            if (showAddAddress) {
              setShowAddAddress(false)
            } else {
              setShowAddAddress(true)
            }
          }}
        >
          お届け先住所を追加
        </RectButton>

        {showAddAddress && (
          <>
            <Stack direction={'row'} alignItems={'center'} gap="50px" sx={{ marginLeft: '80px', marginBottom: '15px' }}>
              <Stack height={40} justifyContent={'center'}>
                <InputTitle sx={{ textAlign: 'right', marginBottom: '15px' }}>お届け先名称</InputTitle>
              </Stack>
              <Stack height={40} justifyContent={'center'}>
                <StyledTextField
                  size="small"
                  sx={{ width: 190 }}
                  id={'name_-1'}
                  {...register(`-1.fullName`, {
                    required: '氏名は未入力です',
                    maxLength: { value: 30, message: '氏名は30桁以上は入力できません' },
                    pattern: {
                      value: /^[a-zA-Z\s0-9ぁ-んァ-ヶー一-龥々]+$/,
                      message: '記号文字は入力できません'
                    }
                  })}
                  inputProps={{ maxLength: 30 }}
                  error={Boolean(errors['-1']?.fullName?.message)}
                  helperText={errors['-1']?.fullName?.message?.toString()}
                />
              </Stack>
            </Stack>

            <Stack direction={'row'} alignItems={'center'} gap="50px" sx={{ marginLeft: '80px', marginBottom: '15px' }}>
              <Stack height={40} justifyContent={'center'}>
                <InputTitle sx={{ textAlign: 'right', marginBottom: '15px' }}>フリガナ</InputTitle>
              </Stack>
              <Stack height={40} justifyContent={'center'}>
                <StyledTextField
                  size="small"
                  sx={{ width: 190 }}
                  id={'fullNameKana_-1'}
                  {...register(`-1.fullNameKana`, {
                    required: 'フリガナは未入力です',
                    maxLength: { value: 30, message: 'フリガナは30桁以上は入力できません' },
                    pattern: {
                      value: /^[a-zA-Z\s0-9ぁ-んァ-ヶー一-龥々]+$/,
                      message: '記号文字は入力できません'
                    }
                  })}
                  inputProps={{ maxLength: 30 }}
                  error={Boolean(errors['-1']?.fullNameKana?.message)}
                  helperText={errors['-1']?.fullNameKana?.message?.toString()}
                />
              </Stack>
            </Stack>

            <Stack direction={'row'} alignItems={'center'} gap="50px" sx={{ marginLeft: '80px', marginBottom: '15px' }}>
              <Stack height={40} justifyContent={'center'}>
                <InputTitle sx={{ textAlign: 'right', marginBottom: '15px' }}>電話番号</InputTitle>
              </Stack>
              <Stack height={40} justifyContent={'center'}>
                <StyledTextField
                  size="small"
                  sx={{
                    width: 190,
                    '&  .MuiFormHelperText-root.Mui-error': {
                      marginRight: '-150px'
                    }
                  }}
                  id={'phone_-1'}
                  {...register(`-1.phone`, {
                    required: '電話番号は未入力です',
                    maxLength: { value: 11, message: '電話番号は11桁以上は入力できません' },
                    pattern: {
                      value:
                        /^(0[5-9]0[-(]?[0-9]{4}[-)]?[0-9]{4}|0120[-]?\d{1,3}[-]?\d{4}|050[-]?\d{4}[-]?\d{4}|0[1-9][-]?\d{1,4}[-]?\d{1,4}[-]?\d{4})*$/,
                      message: '有効な電話番号を入力してください'
                    }
                  })}
                  inputProps={{ maxLength: 11 }}
                  error={Boolean(errors['-1']?.phone?.message)}
                  helperText={errors['-1']?.phone?.message?.toString()}
                />
              </Stack>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} gap="50px" sx={{ marginLeft: '80px', marginBottom: '5px' }}>
              <Stack height={40} justifyContent={'center'}>
                <InputTitle sx={{ textAlign: 'right', marginBottom: '15px' }}>郵便番号</InputTitle>
              </Stack>
              <Stack height={40} justifyContent={'center'}>
                <StyledTextField
                  size="small"
                  sx={{
                    width: 190,
                    '&  .MuiFormHelperText-root.Mui-error': {
                      marginRight: '-150px'
                    }
                  }}
                  id={'postalCode_-1'}
                  {...register(`-1.postalCode`, {
                    required: '郵便番号は未入力です',
                    maxLength: { value: 7, message: '郵便番号は7桁以上は入力できません' },
                    pattern: {
                      value: /^[0-9]{7}$/,
                      message: 'ハイフン無しの有効な郵便番号を入力してください'
                    }
                  })}
                  inputProps={{ maxLength: 7 }}
                  error={Boolean(errors['-1']?.postalCode?.message)}
                  helperText={errors['-1']?.postalCode?.message?.toString()}
                />
              </Stack>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} gap="50px" sx={{ marginLeft: '80px' }}>
              <InputTitle sx={{ textAlign: 'right', marginBottom: '15px' }}>住所</InputTitle>
              <Stack>
                <StyledTextField
                  size="small"
                  sx={{ width: '100%' }}
                  id={'address_-1'}
                  {...register(`-1.address`, {
                    required: '住所は未入力です',
                    maxLength: { value: 50, message: '住所は50桁以上は入力できません' }
                  })}
                  inputProps={{ maxLength: 100 }}
                  error={Boolean(errors['-1']?.address?.message)}
                  helperText={
                    errors['-1']?.address
                      ? errors['-1']?.address?.message?.toString()
                      : '※マンション名や部屋番号等がある場合はすべて入力してください'
                  }
                />
              </Stack>
            </Stack>
          </>
        )}
      </Stack>
    </CommonDialog>
  )
}

const StyledTextField = styled(TextField)((a) => ({
  width: a.fullWidth ? '100%' : 350,
  '.MuiInputBase-root': {
    background: '#FFFFFF'
  }
}))
